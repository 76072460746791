import Vue from "vue";
function getchildrenH (el) {
  let sum = 0;
  for (let i = 0; i < el.children.length; i++) {
    sum += el.children[i].scrollHeight;
  }
  return sum;
};
function listenerEl (el) {
  // 监听元素滚动,并使该元素可以滚动
  el.addEventListener("touchmove", function(e) {
    e.isSCROLL = true;
  });
};

function inserted(el) {
  const clientH = el.clientHeight;
  // 监听元素滚动,并使该元素可以滚动
  el.addEventListener("touchmove", function(e) {
    e.isSCROLL = el.scrollHeight > clientH;
  });
};

function componentUpdated(el) {
  const sumH = getchildrenH(el);
  const offsetH = el.clientHeight;
  if (sumH > offsetH) {
    // 元素高度大于可见高度,则该元素允许滚动
    listenerEl(el);
  }
}

export default {
  inserted,
  componentUpdated
};
