<template>
  <section
    :class="isUp ? 'up-bottom' : 'bottom-up'"
    @click.prevent="movePrevet"
  >
    <div
      v-if="isShow"
      class="mask"
      @click="hide"
    />
    <div
      class="dialog-box"
      :class="isShow ? 'show' : 'hidden'"
    >
      <slot name="header" />
      <slot name="content" />
      <slot name="button" />
    </div>
  </section>
</template>

<script>
const COMPONENT_NAME = 'custom-popup';
import '@/directive/allowScroll.js'; // v-allowScroll

import { ModalHelper } from '@/lib/until.js';

export default {
  name: COMPONENT_NAME,
  props: {
    isUp: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      isShow: false
    };
  },
  methods: {
    hide() {
      this.isShow = false;
      ModalHelper('modal-open').beforeClose();
    },
    movePrevet() {}
  }
};
</script>

<style  lang="scss"  scoped>
.mask {
  position: fixed;
  top: 0;
  z-index: 100;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.4);
}
.dialog-box {
  position: fixed;
  // top: 10vh;
  left: 50%;
  z-index: 101;
  width: 100%;
  height: 90%;
  overflow: scroll;
  background: #fff;
  // border-radius: 10px 10px 0 0;
  transition: all .3s ease-in-out;
}
.bottom-up{
  .show {
    transform: translate(-50%, 0);
  }

  .hidden {
    transform: translate(-50%, 100%);
  }
  .dialog-box{
    top: 10%;
    border-radius: 10px 10px 0 0;
  }
}
.up-bottom{
  .show {
    transform: translate(-50%, 100%);
  }

  .hidden {
    transform: translate(-50%, -20%);
  }
  .dialog-box{
    top: -90%;
    // border-radius: 10px 10px 0 0;
  }
}
</style>
