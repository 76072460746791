<template>
  <li
    class="screen-li"
  >
    <div class="screen-title">
      {{ data.text }}
    </div>
    <div
      v-if="data.dep"
      class="screen-value"
    >
      <i class="icon-joyo arrows">&#xe728;</i>
    </div>
  </li>
</template>

<script>
export default {
  props: {
    data: {
      type: Object,
      default: () => {
        return {};
      }
    }
  }
};

</script>

<style lang="scss" scoped>
  .screen-li{
    display: flex;
    align-items: center;
    justify-content: space-between;
    min-height:50px ;
    border-bottom:1px solid $color-E6;
    .screen-title{
      color: #23252E;
      font-size: $font-15;
    }
    .screen-value{
      .arrows{
        margin-right: 15px;
        color: $color-theme;
        font-size: 11px;
      }
    }
  }
  .screen-li:last-child{
    border: 0;
  }
</style>
