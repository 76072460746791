<template>
  <div>
    <div class="filter-box">
      <ul class="filter-ul">
        <select-picker
          v-if="$route.params.i !== '0'"
          :data="filterData.userId"
          @toTree="toTree"
        />
        <select-picker :data="filterData.clueSource" />
        <!-- <select-picker :data="filterData.clueStatus" /> -->
        <select-picker
          v-if="$route.params.i !== '0'"
          :data="filterData.branchOrgIdList"
          @toTree="toBranchSelect"
        />
        <!-- <select-picker
          v-if="$route.params.i !== '0'"
          :data="filterData.branchOrgIdList"
        /> -->
      </ul>
    </div>
    <div class="filter-box">
      <ul class="filter-ul">
        <date-picker :data="filterData.startTime" />
        <date-picker :data="filterData.endTime" />
      </ul>
    </div>
    <div class="bottom-postion">
      <cube-button
        :outline="true"
        class="btn btns"
        @click="reset"
      >
        重置
      </cube-button>
      <cube-button
        class="btn btns"
        @click="confirm"
      >
        确认
      </cube-button>
    </div>
    <custom-popup
      ref="customPopups"
      :is-up="true"
    >
      <div
        slot="content"
        class="dialog-detail filter-box marginNo"
      >
        <ul class="filter-ul">
          <select-check
            v-for="(item, index) of filterData.branchOrgIdList.options"
            :key="index"
            :data="item"
            @click.native="subscriptOperat(index)"
          />
        </ul>
      </div>
      <div
        slot="button"
        class="bottom-div"
      >
        <div
          class="btn-div"
          @click="returns"
        >
          返回
        </div>
        <div
          class="btn-divs"
          @click="ensureBanch"
        >
          确定
        </div>
      </div>
    </custom-popup>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from 'vuex';

import datePicker from '_c/picker/date';
import selectPicker from '_c/picker/select';
import selectCheck from '_c/picker/select-check';
import customPopup from '_c/popup/custom-popup';

export default {
  name: 'ClueFilter',
  components: {
    selectPicker,
    datePicker,
    selectCheck,
    customPopup
  },
  data() {
    return {
      filterData: {
        userId: {
          title: '负责销售',
          name: '',
          value: '',
          dap: false,
          typeName: 'userId'
        },
        clueSource: {
          title: '线索来源',
          name: '',
          value: '',
          options: []
        },
        branchOrgIdList: {
          title: '分公司',
          name: '',
          value: [],
          dap: false,
          options: []
        },
        startTime: {
          title: '创建开始时间',
          name: '',
          value: '',
          min: [2000, 1, 1],
          max: [2050, 10, 20]
        },
        endTime: {
          title: '创建结束时间',
          name: '',
          value: '',
          min: [2000, 1, 1],
          max: [2050, 10, 20]
        }
      }
    };
  },
  computed: {
    ...mapGetters('clue', ['filterObj']),
    ...mapGetters(['dictList', 'branchList'])
  },
  watch: {
    branchList(newValue) {
      this.filterData.branchOrgIdList.options = newValue;
    }
  },
  mounted() {
    // 获取分公司
    this.getRegionFiliales(2);
    this.getOptions();
    this.getSellData();
  },
  methods: {
    ...mapMutations('clue', ['CLUE_FILTER']),
    ...mapMutations(['CLEAR_FILTER']),
    ...mapActions(['getRegionFiliales']),
    confirm() {
      this.cache();
      this.$router.go(-1);
    },
    reset() {
      for (const i in this.filterData) {
        this.filterData[i].name = '';
        if (i === 'branchOrgIdList') {
          this.filterData[i].value = [];
        } else {
          this.filterData[i].value = '';
        }
      }
      this.CLEAR_FILTER('clue');
    },
    cache() {
      const filterData = {};
      for (const i in this.filterData) {
        filterData[i] = {
          name: this.filterData[i].name,
          value: this.filterData[i].value
        };
      }
      this.CLUE_FILTER(filterData);
    },
    toTree(typeName) {
      this.cache();
      this.$router.push(
        {
          name: 'tree',
          params: {
            routerName: this.$route.name,
            typeName
          }
        },
      );
    },
    toBranchSelect() {
      this.$refs.customPopups.isShow = true;
    },
    getSellData() {
      if (JSON.stringify(this.filterObj) !== '{}') {
        for (const i in this.filterObj) {
          if (!this.filterData[i]) continue
          if (i === 'branchOrgIdList') {
            this.filterData[i].name = this.filterObj[i].name;
            this.filterData[i].value = this.filterObj[i].value;
            if (this.filterObj[i].value.length) {
              this.filterData.branchOrgIdList.value.forEach(item => {
                this.filterData.branchOrgIdList.options.forEach(itemA => {
                  if (item === itemA.value) {
                    itemA.dep = true;
                  }
                });
              });
            } else {
              this.filterData[i].value = [];
            }
          } else {
            this.filterData[i].name = this.filterObj[i] ? this.filterObj[i].name : '';
            this.filterData[i].value = this.filterObj[i] ? this.filterObj[i].value : '';
          }
        }
      }
    },
    getOptions() {
      this.filterData.clueSource.options = this.dictList.clueSource;
      this.filterData.branchOrgIdList.options = this.branchList;
    },
    subscriptOperat(index) {
      this.filterData.branchOrgIdList.options[index].dep = !this.filterData.branchOrgIdList.options[index].dep;
    },
    ensureBanch() {
      const list = [];
      this.filterData.branchOrgIdList.options.forEach(item => {
        if (item.dep === true) {
          list.push(item);
        }
      });
      this.filterData.branchOrgIdList.value = list.map(item => item.value);
      this.filterData.branchOrgIdList.name = list.map(item => item.text).join(',');
      this.cache();
      this.returns();
    },
    returns() {
      this.$refs.customPopups.isShow = false;
    }
  }
};

</script>

<style lang="scss" scoped  src="@/assets/css/components/filter.scss"></style>
<style lang="scss" scoped  src="@/assets/css/components/btn-bottom.scss"></style>
<style lang="scss" scoped >
.dialog-detail{
  z-index: 102;
  height: calc(90vh - 46px);
  overflow-y: auto;
}
.marginNo{
  margin: 0
}
</style>
